   
import lightGallery from 'lightgallery';

// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

   const lc = 'A0971B0D-374A-47F3-B2C9-6C87FDC3088E';

   // Icons
   const CLOSE_BTN = `<button class="c-button c-button--primary carousel__button c-button--icon-only" id="js-close" title="Close"><svg class="c-icon c-icon--sm" aria-hidden="true" focusable="false"><use xlink:href="/assets/icons/sprite.svg#cross-24"></use></svg></button>`
   const NEXT_BTN = `<button id="js-next" title="Next" class="carousel__button c-button c-button--primary c-button--icon-only"><svg class="c-icon c-icon--sm" aria-hidden="true" focusable="false"><use xlink:href="/assets/icons/sprite.svg#chevron-right-24"></use></svg></button>`
   const PREV_BTN = `<button id="js-prev" title="Prev" class="carousel__button c-button c-button--primary c-button--icon-only"><svg class="c-icon c-icon--sm" aria-hidden="true" focusable="false"><use xlink:href="/assets/icons/sprite.svg#chevron-left-24"></use></svg></button>`
   
      
      const customButtons = `${NEXT_BTN} ${PREV_BTN}`;
   
      function createCreditsCaptions(caption, credits){
          const creditsTemplate = `<span class="c-media__credits">${credits}</span>`
   
          return `<div class="lightbox__caption">${caption} ${credits ? creditsTemplate : ''} </div>`
      }
   
      function processData(d){
      
          const src = d.dataset.src;
              const thumb = d.dataset.thumb;
              let subHtml = d.dataset.subhtml || '';
              const srcset =  d.dataset.srcset;
              const sizes =  d.dataset.sizes;
              const caption = d.dataset?.caption ;
              const credits =  d.dataset?.credits;
              if (caption || credits){
                  const credtitsCaptions = createCreditsCaptions(caption, credits)
                  subHtml += credtitsCaptions;
              }
   
              return {
                  src,
                  ... (thumb && {thumb}),
                  ... (subHtml && {subHtml}),
                  ... (srcset && {srcset}),
                  ... (sizes && {sizes}),
              }
   
   
      }
      function collectLightboxData($trigger) {
   
          // single image
          const data_group = $trigger.dataset.group;
          const triggers = document.querySelectorAll(`[data-group="${data_group}"]`)
   
          const triggerEl = data_group ? triggers : [$trigger];
   
          
          return [...triggerEl].map(el => processData(el))
   
   
   
      }
   
   
      function cleanUp(event) {
          console.log(event)
          event.detail.instance.destroy()
      }
   
   
      function initLightBox() {
   
          const lightboxData = collectLightboxData(this)
   
          console.log(lightboxData)
          const dataIndex = lightboxData.findIndex(el => el.srcset == this.dataset.srcset)
          console.log(dataIndex)
          const isSlideshow = lightboxData.length > 1
          // more lightbox (lightgallery) settings: https://www.lightgalleryjs.com/docs/settings/
          const lb = lightGallery(this, {
              addClass: 'c-lightbox',
              licenseKey: lc,
              speed: 500,
              //zoom plugin 
              //plugins: [lgZoom],
              dynamic: true,
              dynamicEl: lightboxData,
              // closes lb with click on black area
              //closeOnTap: false,
              // hides controls
              controls: false,
              //hides counter
              counter: !!isSlideshow,
              //show download bt
              download: false,
              toolbar: false,
              //enable loop
              //loop:true,
              mode: 'lg-slide', // lg-fade  ...
              //no scrollbar when overlay is open
              hideScrollbar: true,
              // allow image to extend behind caption bar or toolbar
              allowMediaOverlap: false
   
          });
          //add controls
          const $toolbar = lb.outer.find(".lg-toolbar");
          const $btn = lb.outer.find(".lg-close");
          const $counter = lb.outer.find(".lg-counter");
   
          $counter?.firstElement?.classList.add('lg-counter--custom')
          console.log($counter);
          
          //$counter.classList.add('lg-counter--custom')
          $btn.remove();
          $toolbar.append(CLOSE_BTN)
   
          document.getElementById("js-close").addEventListener("click", () => {
                  lb.closeGallery();   
          });
           
   
   
   
          if (isSlideshow){
      
          
              const $instanceOuter = lb.outer;
              $instanceOuter.append(customButtons);
   
   
              document.getElementById("js-next").addEventListener("click", () => {
                  lb.goToNextSlide();
                  //console.log('click', lb);
                  
              });
              document.getElementById("js-prev").addEventListener("click", () => {
                  lb.goToPrevSlide();
                  //console.log('click', lb);
              });
          }
   
   
          lb.openGallery(dataIndex);
   
   
          
     
          // $toolbar.prepend(customButtons);
   
          
          this.addEventListener('lgAfterClose', cleanUp)
          
   
   
      }
   
   
   export default initLightBox
   