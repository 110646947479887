import videojs from 'video.js';

videojs.addLanguage('de', {
    Play: 'Wiedergabe',
    Pause: 'Pause',
    Replay: 'Erneut abspielen',
    'Current Time': 'Aktueller Zeitpunkt',
    Duration: 'Dauer',
    'Remaining Time': 'Verbleibende Zeit',
    'Stream Type': 'Streamtyp',
    LIVE: 'LIVE',
    Loaded: 'Geladen',
    Progress: 'Status',
    Fullscreen: 'Vollbild',
    'Exit Fullscreen': 'Vollbildmodus beenden',
    Mute: 'Stumm schalten',
    Unmute: 'Ton einschalten',
    'Playback Rate': 'Wiedergabegeschwindigkeit',
    Subtitles: 'Untertitel',
    'subtitles off': 'Untertitel aus',
    Captions: 'Untertitel',
    'captions off': 'Untertitel aus',
    Chapters: 'Kapitel',
    'You aborted the media playback': 'Sie haben die Videowiedergabe abgebrochen.',
    'A network error caused the media download to fail part-way.':
        'Der Videodownload ist aufgrund eines Netzwerkfehlers fehlgeschlagen.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.':
        'Das Video konnte nicht geladen werden, da entweder ein Server- oder Netzwerkfehler auftrat oder das Format nicht unterstützt wird.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.':
        'Die Videowiedergabe wurde entweder wegen eines Problems mit einem beschädigten Video oder wegen verwendeten Funktionen, die vom Browser nicht unterstützt werden, abgebrochen.',
    'No compatible source was found for this media.': 'Für dieses Video wurde keine kompatible Quelle gefunden.',
    'Play Video': 'Video abspielen',
    Close: 'Schließen',
    'Modal Window': 'Modales Fenster',
    'This is a modal window': 'Dies ist ein modales Fenster',
    'This modal can be closed by pressing the Escape key or activating the close button.':
        'Durch Drücken der Esc-Taste bzw. Betätigung der Schaltfläche "Schließen" wird dieses modale Fenster geschlossen.',
    ', opens captions settings dialog': ', öffnet Einstellungen für Untertitel',
    ', opens subtitles settings dialog': ', öffnet Einstellungen für Untertitel',
    ', selected': ', ausgewählt',
    'captions settings': 'Untertiteleinstellungen',
    'subtitles settings': 'Untertiteleinstellungen',
    'descriptions settings': 'Einstellungen für Beschreibungen',
    'Close Modal Dialog': 'Modales Fenster schließen',
    Descriptions: 'Beschreibungen',
    'descriptions off': 'Beschreibungen aus',
    'The media is encrypted and we do not have the keys to decrypt it.':
        'Die Entschlüsselungsschlüssel für den verschlüsselten Medieninhalt sind nicht verfügbar.',
    ', opens descriptions settings dialog': ', öffnet Einstellungen für Beschreibungen',
    'Audio Track': 'Tonspur',
    Text: 'Schrift',
    White: 'Weiß',
    Black: 'Schwarz',
    Red: 'Rot',
    Green: 'Grün',
    Blue: 'Blau',
    Yellow: 'Gelb',
    Magenta: 'Magenta',
    Cyan: 'Türkis',
    Background: 'Hintergrund',
    Window: 'Fenster',
    Transparent: 'Durchsichtig',
    'Semi-Transparent': 'Halbdurchsichtig',
    Opaque: 'Undurchsichtig',
    'Font Size': 'Schriftgröße',
    'Text Edge Style': 'Textkantenstil',
    None: 'Kein',
    Raised: 'Erhoben',
    Depressed: 'Gedrückt',
    Uniform: 'Uniform',
    'Drop shadow': 'Schlagschatten',
    'Font Family': 'Schriftfamilie',
    'Proportional Sans-Serif': 'Proportionale Sans-Serif',
    'Monospace Sans-Serif': 'Monospace Sans-Serif',
    'Proportional Serif': 'Proportionale Serif',
    'Monospace Serif': 'Monospace Serif',
    Casual: 'Zwanglos',
    Script: 'Schreibschrift',
    'Small Caps': 'Small-Caps',
    Reset: 'Zurücksetzen',
    'restore all settings to the default values': 'Alle Einstellungen auf die Standardwerte zurücksetzen',
    Done: 'Fertig',
    'Caption Settings Dialog': 'Einstellungsdialog für Untertitel',
    'Beginning of dialog window. Escape will cancel and close the window.':
        'Anfang des Dialogfensters. Esc bricht ab und schließt das Fenster.',
    'End of dialog window.': 'Ende des Dialogfensters.',
    'Audio Player': 'Audio-Player',
    'Video Player': 'Video-Player',
    'Progress Bar': 'Fortschrittsbalken',
    'progress bar timing: currentTime={1} duration={2}': '{1} von {2}',
    'Volume Level': 'Lautstärke',
    '{1} is loading.': '{1} wird geladen.',
    'Seek to live, currently behind live': 'Zur Live-Übertragung wechseln. Aktuell wird es nicht live abgespielt.',
    'Seek to live, currently playing live': 'Zur Live-Übertragung wechseln. Es wird aktuell live abgespielt.',
    'Exit Picture-in-Picture': 'Bild-im-Bild-Modus beenden',
    'Picture-in-Picture': 'Bild-im-Bild-Modus',
    'No content': 'Kein Inhalt',
    Color: 'Farbe',
    Opacity: 'Deckkraft',
    'Text Background': 'Texthintergrund',
    'Caption Area Background': 'Hintergrund des Untertitelbereichs',
    'Playing in Picture-in-Picture': 'Wird im Bild-im-Bild-Modus wiedergegeben',
    'Skip forward {1} seconds': '{1} Sekunden vorwärts',
    'Skip backward {1} seconds': '{1} Sekunden zurück',
});

document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('.video-js')) {
        const videos = document.querySelectorAll('.video-js');

        videos.forEach((video) => {
            videojs(video, {
                controls: true,
                autoplay: false,
                preload: 'metadata',
                language: 'de',
                controls: 'true',
                playbackRates: video.dataset.playbackrates
                    ? video.dataset.playbackrates != 'false'
                        ? video.dataset.playbackrates.split(',').map(Number)
                        : []
                    : [0.5, 1, 1.5, 2], // false is false
                controlBar: {
                    skipButtons: {
                        forward: video.dataset.skipbuttons ? parseInt(video.dataset.skipbuttons) : 10, //0 is false
                        backward: video.dataset.skipbuttons ? parseInt(video.dataset.skipbuttons) : 10, //0 is false
                    },
                },
            });
        });
    }
});
