class Accordion {
    constructor($module) {
        this.$module = $module;
        this.$toggleList = this.$module.querySelectorAll('.js-accordion__header');

        // bind `this` to methods where needed
        this.addListenersAndExpandHash = this.addListenersAndExpandHash.bind(this);
        this.toggleContent = this.toggleContent.bind(this);
    }
    init() {
        if (!this.$module || !this.$toggleList.length) {
            return;
        }
        this.setHash();
        window.addEventListener('DOMContentLoaded', this.addListenersAndExpandHash);
    }
    setHash() {
        this.hash = window.location.hash;
    }
    addListenersAndExpandHash() {
        window.removeEventListener('DOMContentLoaded', this.addListenersAndExpandHash);
        this.$toggleList.forEach(($toggle) => {
            this.addClickListener($toggle);
            if ($toggle.id === this.hash.substring(1)) {
                this.toggleContent(null, $toggle);
            }
        });
        this.addHashChangeListener();
    }
    addClickListener($toggle) {
        $toggle.addEventListener('click', this.toggleContent);
    }
    addHashChangeListener() {
        window.addEventListener(
            'hashchange',
            function () {
                this.setHash();
                const shouldToggle = [...this.$toggleList].filter(($toggle) => $toggle.id === this.hash.substring(1));
                if (shouldToggle.length) {
                    this.toggleContent(null, shouldToggle[0]);
                }
            }.bind(this)
        );
    }
    toggleContent(event, $toggle) {
        if (event) {
            $toggle = event.target.closest('.js-accordion__header');
            $toggle.removeEventListener('click', this.toggleContent);
        }
        const $content = this.getNextSibling($toggle, '.js-accordion__content');
        const isExpanded = $toggle.getAttribute('aria-expanded') === 'true' || false;
        $toggle.setAttribute('aria-expanded', !isExpanded);
        const contentHeight = $content.offsetHeight + 'px';
        if (isExpanded) {
            $toggle.classList.remove('h-is-expanded');
            $content.style.maxHeight = contentHeight;

            // set timeout, so Firefox will fire transition event
            setTimeout(() => {
                $content.addEventListener('transitionend', onTransitionEnd);
                $content.style.maxHeight = '0px';
            }, 50);
        } else {
            $toggle.classList.add('h-is-expanded');
            $content.style.maxHeight = '0px';
            $content.classList.add('h-is-expanded');

            // set timeout, so Firefox will fire transition event
            setTimeout(() => {
                $content.addEventListener('transitionend', onTransitionEnd);
                $content.style.maxHeight = contentHeight;
            }, 50);
        }
        const onTransitionEnd = () => {
            $content.removeEventListener('transitionend', onTransitionEnd);
            if (isExpanded) {
                $content.classList.remove('h-is-expanded');
            }
            $content.style.maxHeight = null;
            if (event) {
                $toggle.addEventListener('click', this.toggleContent);
            }
        };
    }
    getNextSibling(elem, selector) {
        let sibling = elem.nextElementSibling;
        if (!selector) {
            return sibling;
        }
        while (sibling) {
            if (sibling.matches(selector)) {
                return sibling;
            }
            sibling = sibling.nextElementSibling;
        }
    }
}

export default Accordion;
