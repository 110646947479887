/**
 * @todo Expand native keyboard navigation support (i.e. escape key)
 * @todo Consider combining toggleNav and toggleNavLevel
 * @todo Add tab trap
 */
const navMobile = function () {
    const slideNavElem = document.querySelector(
        '[data-js-selector="c-slide-nav"]'
    );

    if (!slideNavElem) {
        return;
    }

    const allNavToggleElems = slideNavElem.querySelectorAll(
        '[data-js-toggle="c-slide-nav"]'
    );
    const allAriaExpandedElems =
        slideNavElem.querySelectorAll('[aria-expanded]');
    const allNavLevelToggleElems = slideNavElem.querySelectorAll(
        '[data-js-toggle="c-slide-nav-level"]'
    );

    window.addEventListener('resize', handleResize);

    function handleResize(event) {
        let isOpen =
            slideNavElem
                .querySelector('[aria-controls="c-slide-nav-controlled"]')
                .getAttribute('aria-expanded') === 'true';
        const breakpointLg = 960;
        const currentWidth = event.target.innerWidth;

        if (currentWidth >= breakpointLg && isOpen) {
            closeAndResetNav();
        }
    }

    Array.prototype.forEach.call(allNavToggleElems, function (navToggleElem) {
        navToggleElem.addEventListener('click', toggleNav);

        function toggleNav() {
            var allNavToggleButtonElems = slideNavElem.querySelectorAll(
                '[aria-controls="c-slide-nav-controlled"]'
            );
            var isOpen =
                allNavToggleButtonElems[0].getAttribute('aria-expanded') ===
                'true';
            if (isOpen) {
                closeAndResetNav();
            } else {
                // open nav
                Array.prototype.forEach.call(
                    allNavToggleButtonElems,
                    function (navToggleButtonElem) {
                        navToggleButtonElem.setAttribute('aria-expanded', true);
                    }
                );
                toggleScrollLock(true);
            }
        }
    });

    Array.prototype.forEach.call(
        allNavLevelToggleElems,
        function (navLevelToggleElem) {
            navLevelToggleElem.addEventListener('click', toggleNavLevel);

            function toggleNavLevel(event) {
                event.preventDefault();
                event.stopPropagation();

                // only toggle level if click comes from a button
                if (
                    event.target.getAttribute('data-js-toggle') ===
                    'c-slide-nav-level'
                ) {
                    var closestAriaExpandedElem = getClosestElem(
                        event.target,
                        'aria-expanded'
                    );
                    var isOpen =
                        closestAriaExpandedElem.getAttribute(
                            'aria-expanded'
                        ) === 'true';
                    closestAriaExpandedElem.setAttribute(
                        'aria-expanded',
                        !isOpen
                    );

                    // toggle overflow for parent nav list
                    var closestNavList = closestAriaExpandedElem.parentElement;
                    if (
                        closestNavList.classList.contains(
                            'c-slide-nav__list--no-overflow'
                        )
                    ) {
                        closestNavList.classList.remove(
                            'c-slide-nav__list--no-overflow'
                        );
                    } else {
                        closestNavList.scrollTop = 0;
                        closestNavList.classList.add(
                            'c-slide-nav__list--no-overflow'
                        );
                    }
                }

                function getClosestElem(elem, attribute) {
                    var parentElem = elem.parentElement;
                    if (parentElem) {
                        if (parentElem.hasAttribute(attribute)) {
                            return parentElem;
                        }
                        return getClosestElem(parentElem, attribute);
                    }
                    return null;
                }
            }
        }
    );

    function closeAndResetNav() {
        toggleScrollLock(false);

        Array.prototype.forEach.call(allAriaExpandedElems, function (elem) {
            elem.setAttribute('aria-expanded', false);
        });
    }

    function toggleScrollLock(setLock) {
        var htmlElem = document.querySelector('html');
        var headerElem = document.querySelector('.c-header');

        // get scrollbar width to compensate it
        var scrollbox = document.createElement('div');
        scrollbox.style.overflow = 'scroll';
        scrollbox.style.height = '100px';
        document.body.appendChild(scrollbox);

        var scrollBarWidth;

        setTimeout(function () {
            scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;
            document.body.removeChild(scrollbox);
        }, 0);

        if (setLock || !htmlElem.classList.contains('u-scroll-lock')) {
            var topValue = '-' + window.pageYOffset + 'px';
            htmlElem.classList.add('u-scroll-lock');
            if (scrollBarWidth) {
                htmlElem.style.marginRight = scrollBarWidth + 'px';
                headerElem.style.marginRight = scrollBarWidth + 'px';
            }
            htmlElem.style.top = topValue;
            htmlElem.style.scrollBehavior = 'auto';
        } else {
            var scrollYAmount = -parseInt(htmlElem.style.top);
            htmlElem.classList.remove('u-scroll-lock');
            htmlElem.style.marginRight = null;
            headerElem.style.marginRight = null;
            htmlElem.style.top = null;
            window.scrollTo(0, scrollYAmount);
            setTimeout(function () {
                htmlElem.style.scrollBehavior = null;
            }, 0);
        }
    }
};

export default navMobile;
